import React, { FunctionComponent, HTMLProps } from 'react';
import cn from 'classnames';

import './index.scss';

export const SocialMediaIcons: FunctionComponent<React.PropsWithChildren<HTMLProps<HTMLElement>>> = ({ className }) => {
  return (
    <aside className={cn(className, "social-icons")}>
      <a className="social-icons__link linkedin" href="https://www.linkedin.com/in/grahamharper/" title="LinkedIn"><span className="fab fa-linkedin"></span></a>
      <a className="social-icons__link mastodon" href="https://social.grahamharper.net/@harper84" title="Mastodon" rel="me"><span className="fab fa-mastodon"></span></a>
      <a className="social-icons__link threads" href="https://www.threads.net/@grahamalexanderharper" title="Threads"><span className="fa-brands fa-threads"></span></a>
    </aside>
  );
}
